import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useResizeDetector } from 'react-resize-detector';
import PropTypes from 'prop-types';

import * as styles from './scrollableContainer.module.css';
import ChevronRight from '../icons/ChevronRight.svg';

function ScrollableContainer({ children, showButtons }) {
  const scrollbarContainerRef = useRef();

  const {
    width: containerWidth,
    height: containerHeight,
    ref,
  } = useResizeDetector();

  const [leftButtonVisible, setLeftButtonVisible] = useState(false);
  const [rightButtonVisible, setRightButtonVisible] = useState(false);

  const [mouseDown, setMouseDown] = useState(false);

  const [wrapperWidth, setWrapperWidth] = useState(undefined);

  const onMouseUp = () => setMouseDown(false);

  const onMouseMove = e => {
    e.preventDefault();
    e.stopPropagation();

    if (scrollbarContainerRef.current) {
      if (e.pageX < 16) {
        return;
      }
      if (e.pageX > window.innerWidth - 16) {
        return;
      }

      scrollbarContainerRef.current.scrollLeft -= e.movementX;
    }
  };

  useEffect(() => {
    if (mouseDown) {
      window.addEventListener('mouseup', onMouseUp);
      window.addEventListener('mousemove', onMouseMove);

      return () => {
        window.removeEventListener('mouseup', onMouseUp);
        window.removeEventListener('mousemove', onMouseMove);
      };
    } else {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    }
  }, [mouseDown]);

  const onMouseDown = e => {
    if (e.button !== 0) {
      return;
    }

    if (
      scrollbarContainerRef.current &&
      ref.current &&
      scrollbarContainerRef.current.offsetWidth >= ref.current.offsetWidth
    ) {
      return;
    }

    setMouseDown(true);
  };

  const updateScrollButtons = () => {
    const c = scrollbarContainerRef.current;

    let rightScrollButtonVisible = false;
    let leftScrollButtonVisible = false;
    if (c) {
      const scrollUntilMax = c.scrollWidth - (c.clientWidth + c.scrollLeft);
      rightScrollButtonVisible = scrollUntilMax > 0;
      leftScrollButtonVisible = c.scrollLeft > 0;
    }

    setLeftButtonVisible(leftScrollButtonVisible);
    setRightButtonVisible(rightScrollButtonVisible);
  };

  const onContentsResize = contentWidth => {
    updateScrollButtons();
    const width = Math.min(window.outerWidth, 1360);
    const ee = width > contentWidth;
    setWrapperWidth(ee ? '100%' : undefined);
  };

  const {
    ref: contentWrapperRef,
    width: contentWrapperWidth,
  } = useResizeDetector({
    onResize: onContentsResize,
  });

  const onWindowResize = useDebouncedCallback(() => {
    updateScrollButtons();
    if (!ref.current) {
      return;
    }

    const width = Math.min(window.outerWidth, 1360);
    const contentWidth = contentWrapperWidth;

    const ee = width > contentWidth;
    setWrapperWidth(ee ? '100%' : undefined);
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  const onScrollbarScroll = e => {
    if (ref.current) {
      ref.current.style.left = `${-e.target.scrollLeft}px`;
    }
    updateScrollButtons();
  };

  const removeEase = useDebouncedCallback(() => {
    ref.current.style.transition = undefined;
  }, 200);

  const scrollContainer = dx => {
    scrollbarContainerRef.current.scrollLeft += dx;
    ref.current.style.transition = 'left 0.2s ease';
    removeEase();
  };

  return (
    <div className={styles.wrapper}>
      {showButtons && (
        <div className={styles.scrollButtons}>
          <button
            onClick={() => scrollContainer(-316 - 32)}
            className={[
              styles.scrollButton,
              styles.scrollButtonLeft,
              leftButtonVisible ? '' : styles.hidden,
            ].join(' ')}
            style={{
              top: ref.current ? Math.round(ref.current.offsetHeight * 0.5) : 0,
            }}
            aria-label="Scrolla till höger"
          >
            <ChevronRight />
          </button>
          <button
            onClick={() => scrollContainer(316 + 32)}
            className={[
              styles.scrollButton,
              rightButtonVisible ? '' : styles.hidden,
            ].join(' ')}
            style={{
              top: ref.current ? Math.round(ref.current.offsetHeight * 0.5) : 0,
            }}
            aria-label="Scrolla till höger"
          >
            <ChevronRight />
          </button>
        </div>
      )}

      <div className={styles.scrollableContainer}>
        <div
          ref={ref}
          className={styles.scrollContent}
          style={{
            width: wrapperWidth,
          }}
        >
          <div ref={contentWrapperRef}>{children}</div>
        </div>
      </div>
      <div
        ref={scrollbarContainerRef}
        className={styles.scrollbar}
        style={{ height: containerHeight }}
        onScroll={onScrollbarScroll}
        onMouseDown={onMouseDown}
      >
        <div style={{ width: containerWidth }} />
      </div>
    </div>
  );
}

ScrollableContainer.propTypes = {
  children: PropTypes.element,
  showButtons: PropTypes.bool,
};

export default ScrollableContainer;
