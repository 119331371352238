// extracted by mini-css-extract-plugin
export var arrowForward = "contact-module--arrowForward--0727e";
export var contact = "contact-module--contact--648ca";
export var contactDiv = "contact-module--contactDiv--831a3";
export var contactMethod = "contact-module--contactMethod--12772";
export var contactMethodText = "contact-module--contactMethodText--c48aa";
export var contactMethodTitle = "contact-module--contactMethodTitle--219be";
export var contactMethods = "contact-module--contactMethods--6bd51";
export var contactMethodsHeader = "contact-module--contactMethodsHeader--aed19";
export var extraLink = "contact-module--extraLink--ec2db";
export var icon = "contact-module--icon--c3941";
export var link = "contact-module--link--86f8a";
export var linkWrapper = "contact-module--linkWrapper--c9616";
export var subtitle = "contact-module--subtitle--6a2f0";