import React from 'react';
import * as styles from './backNavBar.module.css';
import { Link } from 'gatsby-plugin-intl';
import propTypes from 'prop-types';

import ArrowRight from '@icons/arrow_right_main.svg';

const BackNavBar = ({ text, back, noStyleContainer }) => {
  return (
    <div className={noStyleContainer ? null : styles.container}>
      <Link to={back} className={styles.backArrow}>
        <ArrowRight className={styles.chevron} />
        <div className={styles.text}>{text}</div>
      </Link>
    </div>
  );
};

BackNavBar.propTypes = {
  text: propTypes.string,
  back: propTypes.string,
  noStyleContainer: propTypes.bool,
};

export default BackNavBar;
